<template>
  <header-view />
  <navigation-main />
  <router-view/>
</template>

<script>
import navigationMain from '@/components/uiNav/NavigationMain.vue'
import HeaderView from './components/uiElements/HeaderView.vue'
export default {
  components: { navigationMain, HeaderView }
}
</script>
<style lang="scss">
@import 'assets/scss/style';
</style>
