<template>
  <header>
    <div class="header-bar-top">
    </div>
    <img src="@/assets/acheader.png" />
    <sub>THINK BEFORE YOU STUPID</sub>
  </header>
</template>

<script>
export default {
  name: 'HeaderView'
}
</script>

<style>

</style>