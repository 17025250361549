const state = {
  username: 'Matt',
  email: 'admin@angrycritic.com',
  id: 'null'
}

const getters = {
  username: (state) => state.username,
  email: (state) => state.email,
  id: (state) => state.id
}

const mutations = {
  mutateUserLogin (state, ctx) {
    state.username = ctx
  }
}

const actions = {
  userLogin ({ commit }, ctx) {
    let loginResponse = ctx
    commit('mutateUserLogin', loginResponse)
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}

