import { mapGetters } from 'vuex'

const mixin = {
  data () {
    return {
      width: 100
    }
  },
  computed: {
    ...mapGetters({
      username: 'username'
    })
  },
  methods: {
    openSegment (target) {
      alert(target)
    },
    openExternal (url) {
      window.open(url, '_blank')
    }
  }
}

export default mixin