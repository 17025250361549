<template>
  <div class="home">
    <div class="article">
      <h2>Vote god out</h2>
      <sub>It's best for you and your beliefs</sub>
      <p>No matter what religion you follow or believe in, keeping your beliefs out of politics is in your best interests.  Allowing religion to influence law guarantees that no one's rights, not even yours can or will be respected.  Belief systems fail to deliver equal treatment or justice and beliefs have no roots in reality or the way things actually are.  Religion has been proven wrong more than it has ever been proven correct, religious leaders know this, that is why over time they make concessions and reinterpret the word of their gods.</p>
      <p>No true believer wants to believe that you are right about your beliefs, the true believer knows that they are correct and have an 'absolute truth' they stand by.  Absolute truths don't exist when it comes to topics that have never been shown to be valid or real.  Religion is and always will be nothing more than a belief system.  The founding fathers knew this, they understood there were other beliefs and it would be wrong to sponsor the establishment of just one.  That's what the separation of church and state is for, live your life the way your god wants you to and let others live by their god.</p>
      <h4>Repeat the lie until it's truth</h4>
      <p>A lie is always a lie, no matter what you want to believe.  Religious leaders continue to press the lie that the US is a christian, catholic, hindu, muslim or any sort of religious nation, it isn't, never was and never should be.  Christians in particular push this lie over and over, they accuse everyone else of lying when in their non 'christ' like fashion they are the perpetrators of this lie.  Think for yourself and think why would the fonders want this country to be like the country they went to war with to separate and be better.  If you use your own head to think things out and practice the basic tenants of any religion and treat people well, you'll find it works out for everyone.</p>
      <h4>Lies and the liars that tell them</h4>
      <p>Anyone that speaks on behalf of god or has heard the voice is lying to you.  For there to be a voice, there'd have to be a god.  the evangelicals have prophets speaking on behalf of god and none of what they've said is true or has happened.  So many predictions and all wrong, when will you call them out for this?  Why do you allow this to continue, they treat you like fools and rely on this.  Why do they see angels, hear from god and vanquish the demons that none of us see? All false prophets and money stealing thieves.</p>
      <h4>Religion as the cure</h4>
      <sub>sickness and health</sub>
      <p>Praying hasn't solved disease or illness. Faith healing is a joke and something that should be ridiculed as to how ignorant it is.  Corona virus didn't diminish due to religion or praying, it was science and research handling the issue.  Cancer won't be cured by prayer ask a lot of families that have suffered through it.  Praying and meditation are the same thing a means to calm your mind and provide 'positive vibes', that's all.</p>
      <h4>Religion as a solution</h4>
      <sub>thoughts and prayers</sub>
      <p>The political answer for doing nothing.  Politicians that preach the will of god in their decision making have real world tools to solve the problems we have in this country.  Mass shootings, america's past time, occur on an obscene basis and the roots of it are all tied to the same issues.  Abundance of weapons, access to weapons and people unfit to have or access the weapons.  I am in no way saying people shouldn't be allowed to own weapons, but common sense dictates that people that are criminals, have mental issues or are incapable of understanding the responsibility of owning weapons shouldn't have them.  Prayer hasn't stopped a shooting and thoughts from morons hasn't helped.  Stop relying on religious nonsense to fix real problems.</p>
      <h4>Think about it</h4>
      <p>What problems has religion ever solved.  The right's goals are to make women pets to tiny dick, closet cased emotional degenerates.  Stop voting for morons, if god is on their platform, they're lying to you and taking advantage of the soft spot you've been conditioned to accept.   If your god exists, he'll be proud of the life you lived as long as you didnt beat down on other people.  If you don't understand it or are scared of it that doesn't mean you have to hate it. god is useless.</p>
    </div>
  </div>
</template>

<script>
// import { defineAsyncComponent } from 'vue'
export default {
  components: { 
    //ArticleItem: defineAsyncComponent(() => import('@/components/uiContainers/ArticleItem.vue'))
  },
  name: 'HomeView',
  async mounted () {
    /*
    if(this.$route.params.articleId) {
      this.article = this.articles[`${this.$route.params.articleId}`]
    } else {
      this.article = this.articles['article3']
      //this.article = {}
    }
    */
  },
  data () {
    return {      
    }
  }
}
</script>
