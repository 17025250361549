<template>
  <div class="navigation">
    navigation
  </div>
</template>

<script>
export default {
  name: 'navigationMain'
}
</script>

<style>

</style>